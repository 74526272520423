import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL;
let http;

class RecoverService {
  constructor() {
    http = axios.create({
      baseURL: API_URL,
      headers: authHeader()
    });
    http.interceptors.request.use(
      config => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user && user.token) {
          config.headers.Authorization = "Bearer " + user.token;
        }
        return config;
      },
      error => Promise.reject(error)
    );
  }
  emailToReset({ query }) {
    return http.post("/user/forgot-password", {
      email: query.email
    });
  }

  pwReset({ query }) {
    return http.post("/user/reset-password", {
      token: query.token,
      email: query.user,
      password: query.password,
      password_confirmation: query.password_confirmation
    });
  }
}

export default new RecoverService();
