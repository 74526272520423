<template>
  <div class="login">
    <Header />

    <v-container v-if="!token" class="my-8">
      <v-row>
        <v-col cols="12">
          <h1 class="text-h2">Forgot your password</h1>
          <v-divider class="mt-8 mb-5"></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <p v-if="emailSend">
            Thanks! You should receive an e-mail shortly.
          </p>
          <v-form v-else ref="form" v-model="valid" lazy-validation>
            <p>
              Please confirm the e-mail address used for your account.<br />
              An e-mail will be sent to reset your password.
            </p>
            <v-text-field
              id="login_email"
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
              filled
            ></v-text-field>

            <div class="d-xl-flex justify-space-between">
              <v-btn
                @click="recoverPW"
                rounded
                color="secondary"
                class="btn-login"
                >RESET PASSWORD</v-btn
              >
            </div>
            <br />
            <span v-if="errorSendingEmail" style="color: #860202">{{
              errorSendingEmailMSG
            }}</span>

            <v-alert v-if="message" type="error" class="mt-5">{{
              message
            }}</v-alert>
          </v-form>
        </v-col>
        <v-col cols="12" sm="5" offset-sm="1">
          <v-card class="mx-auto" elevation="8" color="grey lighten-5">
            <v-img height="160px" src="/assets/images/demo.jpg"></v-img>
            <div class="pa-5">
              <DemoCTA size="h3" highlight="primary" />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else class="my-8">
      <v-row>
        <v-col cols="12">
          <h1 class="text-h2">Reset password</h1>
          <v-divider class="mt-8 mb-5"></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <div v-if="pwChanged">
            <p>
              Your password has been reset.
            </p>
            <router-link to="/">
              <v-btn rounded color="secondary" class="btn-login"
                >GO BACK TO LOGIN</v-btn
              >
            </router-link>
          </div>

          <v-form v-else ref="form" v-model="valid" lazy-validation>
            <v-text-field
              id="login_password"
              v-model="password"
              :rules="[v => !!v || 'Password is required']"
              label="New Password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
              required
              counter
              filled
            ></v-text-field>
            <v-text-field
              id="login_password"
              v-model="passwordConf"
              :rules="[v => !!v || 'Password is required']"
              label="Confirm Password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
              required
              counter
              filled
            ></v-text-field>
            <div class="d-xl-flex justify-space-between">
              <v-btn
                @click="changePW"
                rounded
                color="secondary"
                class="btn-login"
                >RESET PASSWORD</v-btn
              >
            </div>

            <br />
            <span v-if="errorSendingPw" style="color: #860202">{{
              errorSendingPwMSG
            }}</span>

            <v-alert v-if="message" type="error" class="mt-5">{{
              message
            }}</v-alert>
          </v-form>
        </v-col>
        <v-col cols="12" sm="5" offset-sm="1">
          <v-card class="mx-auto" elevation="8" color="grey lighten-5">
            <v-img height="160px" src="/assets/images/demo.jpg"></v-img>
            <div class="pa-5">
              <DemoCTA size="h3" highlight="primary" />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import DemoCTA from "@/components/DemoCTA.vue";
import RecoverService from "@/services/recover.service";

export default {
  name: "Recovery",
  components: {
    Header,
    DemoCTA
  },
  data() {
    return {
      password: "",
      passwordConf: "",
      pwChanged: false,
      token: null,
      emailSend: false,
      email: "",
      errorSendingEmailMSG: "",
      errorSendingEmail: false,
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      show: false,
      apiError: "",
      valid: true,
      message: "",
      errorSendingPw: true,
      errorSendingPwMSG: "",
      user: ""
    };
  },
  methods: {
    async recoverPW() {
      try {
        await RecoverService.emailToReset({
          query: {
            email: this.email
          }
        });
        this.errorSendingEmail = false;
        this.emailSend = true;
      } catch (e) {
        this.errorSendingEmail = true;
        this.errorSendingEmailMSG = e.response.data.message;
      }
    },
    async changePW() {
      try {
        await RecoverService.pwReset({
          query: {
            token: this.token,
            user: this.user,
            password: this.password,
            password_confirmation: this.passwordConf
          }
        });
        this.pwChanged = true;
      } catch (e) {
        this.errorSendingPw = true;
        this.errorSendingPwMSG = e.response.data.message;
      }
    }
  },
  mounted() {
    if (this.$route.params.token && this.$route.params.user) {
      this.token = this.$route.params.token;
      this.user = this.$route.params.user;
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none !important;
}
</style>
