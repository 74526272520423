var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center justify-space-between banner-cta"},[_c('h2',{class:{
      'text-h1': _vm.size == 'h1',
      'text-h2': _vm.size == 'h2',
      'text-h3': _vm.size == 'h3',
      'text-h4': _vm.size == 'h4',
      'text-h5': _vm.size == 'h5',
      'text-h6': _vm.size == 'h6'
    }},[_c('span',{class:{
        'primary--text': _vm.highlight == 'primary',
        'secondary--text': _vm.highlight == 'secondary',
        'white--text': _vm.highlight == 'white',
        'black--text': _vm.highlight == 'black'
      }},[_vm._v("Ready to get started?")]),_c('br')]),_c('v-btn',{staticClass:"my-4",attrs:{"rounded":"","large":"","color":"primary","to":"/signup"}},[_vm._v("Purchase plan")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }