<template>
  <div class="d-flex align-center justify-space-between banner-cta">
    <h2
      :class="{
        'text-h1': size == 'h1',
        'text-h2': size == 'h2',
        'text-h3': size == 'h3',
        'text-h4': size == 'h4',
        'text-h5': size == 'h5',
        'text-h6': size == 'h6'
      }"
    >
      <span
        :class="{
          'primary--text': highlight == 'primary',
          'secondary--text': highlight == 'secondary',
          'white--text': highlight == 'white',
          'black--text': highlight == 'black'
        }"
        >Ready to get started?</span
      >
      <br />
    </h2>

    <v-btn rounded large color="primary" to="/signup" class="my-4"
      >Purchase plan</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "DemoCTA",
  props: {
    size: String,
    highlight: String
  }
};
</script>
